import { useContext } from "react";
import { Redirect } from "react-router-dom";
import { AppContext } from "../../contexts/AppContextProvider";

export default function Cancelled() {

  const app = useContext(AppContext);

  app.setAlert("Your payment was cancelled", "warning");

  return <Redirect to="/billing" />
};