import React, {useContext, useEffect, useState} from 'react';
import {Redirect, useHistory} from 'react-router-dom';
import {ApiContext} from '../../contexts/ApiContextProvider';
import {AppContext} from '../../contexts/AppContextProvider';
import queryString from 'query-string';
import {Grid} from '@material-ui/core';
import Page from '../../components/Page';
import _ from 'lodash';

export default function PurchaseSuccess() {
  const [loading, setLoading] = useState(true);

  const app = useContext(AppContext);
  const api = useContext(ApiContext);
  const history = useHistory();

  useEffect(() => {
    if (!api || !app.claims) {
      return;
    }

    const load = async () => {
      setLoading(true);
      try {
        const parsed = queryString.parse(window.location.search) as {sessionId: string};
        if (!parsed.sessionId) {
          throw new Error('sessionId not found');
        }
        await api.billing.purchaseCheckout({
          sessionId: parsed.sessionId
        });
        history.push(`/gallery/${_.kebabCase(`Users/${app.claims.username}`)}`);
      } catch (error) {
        app.setAlert(error.message, 'error');
      } finally {
        setLoading(false);
      }
    };
    load();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, app.claims]);

  return (
    <Page title="Processing Checkout" loading={loading}>
      <Grid item xs={12}>
        <p>{app.status || 'Processing'}</p>
      </Grid>
    </Page>
  );
}
