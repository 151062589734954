import { v4 as uuidv4 } from 'uuid';
import CloudWatchLogger from 'cloudwatch-logger';
import {getConfig} from '../clientConfig';
import { Logger } from '../models/Logger';

const {region, logging} = getConfig();

const config = {
  accessKeyId: logging.accessKeyId,
  secretAccessKey: logging.secretAccessKey,
  region: region,
  logGroupName: logging.logGroup,
  logStreamName: uuidv4(),
};

let ready = false;

/**
 * Track messages while log stream is being created.
 */
const buffer = [];

const logger = new CloudWatchLogger(config);


const send = (m) => {
  if (ready) {
    try {
      logger.log(m);
    } catch (error) {
      console.error(error);
    }
  } else {
    buffer.push(m);
  }
};

(async () => {
  try {
    await logger.connect();
    console.log('Log Stream created');
  } catch (error) {
    console.error('Log Stream creation error', error.message);
  } finally {
    ready = true;
    buffer.forEach((x) => send(x));
  }
})();

const getLogger = (service: string): Logger => ({
  debug: (message: string, ...properties: any[]) => {
    if (['DEBUG', 'INFO', 'WARN', 'ERROR'].includes(logging.level)) {
      console.debug(`${service}:${message}`, properties);
      send({ level: 'debug', service, message, meta: properties });
    }
  },
  info: (message: string, ...properties: any[]) => {
    if (['INFO', 'WARN', 'ERROR'].includes(logging.level)) {
      console.info(`${service}:${message}`, properties);
      send({ level: 'info', service, message, meta: properties });
    }
  },
  warn: (message: string, ...properties: any[]) => {
    if (['WARN', 'ERROR'].includes(logging.level)) {
      console.info(`${service}:${message}`, properties);
      send({ level: 'info', service, message, meta: properties });
    }
  },
  error: (message: string, ...properties: any[]) => {
    if (logging.level === 'ERROR') {
      console.error(`${service}:${message}`, properties);
      send({ level: 'error', service, message, meta: properties });
    }
  },
});

export { getLogger };
