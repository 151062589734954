import _ from 'lodash';
import React, {useContext, useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';
import { getConfig } from '../clientConfig';
import Page from '../components/Page';
import {ApiContext} from '../contexts/ApiContextProvider';
import {AppContext} from '../contexts/AppContextProvider';
import {AppConfigPublic} from '../shared/api-models';

const {name} = getConfig();

export default function Home() {
  const app = useContext(AppContext);
  const api = useContext(ApiContext);

  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState<AppConfigPublic>();

  useEffect(() => {
    if (api) {
      const load = async () => {
        setLoading(true);
        try {
          const result = await api.config.getConfig();
          setConfig(result);
        } catch (error) {
          app.setAlert(error.message, 'error');
        } finally {
          setLoading(false);
        }
      };
      load();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  if (app.claims) {
    return <Redirect to="/galleries" />;
  }

  const message = _.trim(config?.loginPageMessage || '');
  const content = message.split('\n').map((x) => (
    <>
      {x}
      <br />
    </>
  ));

  return (
    <Page title={name} showAuthenticator={true} loading={loading}>
      <h2>Welcome!</h2>
      <p>{content}</p>
    </Page>
  );
}
