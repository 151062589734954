import * as Yup from 'yup';
import {OptionalObjectSchema} from 'yup/lib/object';

export interface IValidationErrors {
  [name: string]: string[];
}

export interface IValidationResult<TModel> {
  isValid: boolean;
  errors: IValidationErrors;
  /**
   * Validation will remove unknown fields
   */
  data: TModel;
}

export const validateSchema = <TModel>(schema: OptionalObjectSchema<any>, data: TModel): IValidationResult<TModel> => {
  const response: IValidationResult<TModel> = {
    isValid: true,
    errors: {},
    data: undefined
  };
  try {
    response.data = schema.validateSync(data, {abortEarly: false, stripUnknown: true});
  } catch (error) {
    response.isValid = false;
    error.inner.forEach((e: any) => {
      response.errors[e.path] = e.errors;
    });
  }
  return response;
};

// const phoneFormat = () => {
//     return Yup.string()
//         .required("Phone is required")
//         .matches(
//             /^\d{1,10}$/,
//             "Phone must be 10 digits"
//         );
// };

// const emailFormat = () => {
//     return Yup.string().lowercase("Email must be lower case").required("Email is required").email();
// };

export const SendMessageRequestSchema = Yup.object({
  text: Yup.string().required('Message text is required').max(1024)
});

export const AppConfigSchema = Yup.object({
  loginPageMessage: Yup.string().max(4098),
  welcomeMessage: Yup.string().max(4098),
  subscriptionRequiredMessage: Yup.string().max(4098)
});
