import {useContext, useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {BillingConfig, GalleryImages, Image} from '../../shared/api-models';
import {ApiContext} from '../../contexts/ApiContextProvider';
import {AppContext} from '../../contexts/AppContextProvider';
import {Grid, Box, Button} from '@material-ui/core';
import MaterialTable from 'material-table';
import Page from '../../components/Page';
import {getLogger} from '../../services/LoggingService';
import { loadStripe, Stripe } from '@stripe/stripe-js';

const logger = getLogger('GalleryEdit');

interface IParams {
  id: string;
}

export const GalleryPurchase = () => {
  const {id} = useParams() as IParams;

  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState<BillingConfig>();
  const [stripe, setStripe] = useState<Stripe>();

  const [gallery, setGallery] = useState<GalleryImages>(null);

  const {setAlert} = useContext(AppContext);
  const api = useContext(ApiContext);

  const refresh = () => {
    api.galleries
      .getImages(id)
      .then((data) => {
        setGallery(data);
        setLoading(false);
      })
      .catch((error) => {
        setAlert(error.message, 'error');
        setLoading(false);
      });
  };

  useEffect(() => {
    if (gallery && !stripe) {
      const load = async () => {
        setLoading(true);
        try {
          const result = await api.billing.config();
          setConfig(result);
          const stripeObj = await loadStripe(result.publicKey);
          setStripe(stripeObj);
        } catch (error) {
          setAlert(error.message, "error");
        } finally {
          setLoading(false);
        }
      }
      load();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gallery]);

  useEffect(() => {
    if (api && id) {
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, id]);

  const purchaseFile = async (key: string) => {
    if(!stripe){
      return;
    }
    try {
      try {
        const model = await api.billing.purchase({key});
        const result = await stripe.redirectToCheckout({
          sessionId: model.sessionId,
        });
        if (result.error) {
          throw result.error;
        }
      } catch (error) {
        setAlert(error.message, "error");
      } finally {
        setLoading(false);
      }
    } catch (error) {
      logger.error('purchaseFile: ' + error.message, error);
      setAlert(error.message, 'error');
    }
  };

  if (!gallery) {
    return null;
  }

  return (
    <Page title={gallery.name} loading={loading}>
      <Grid item xs={12}>
        <MaterialTable
          title=""
          components={{
            Container: (props) => <Box>{props.children}</Box>
          }}
          columns={[
            {
              title: 'Name',
              field: 'name'
            },
            {
              title: '',
              field: 'name',
              render: (row: Image) => {
                if (row.status === 'unlocked') {
                  return (
                    <Box marginRight={2}>
                      <a target="_blank" rel="noreferrer" href={row.link}>
                        <Button variant="contained" color="secondary">
                          View
                        </Button>
                      </a>
                    </Box>
                  );
                } else {
                  return (
                    <Box marginRight={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => purchaseFile(`${gallery.key}/${row.name}`)}
                      >
                        Purchase
                      </Button>
                    </Box>
                  );
                }
              }
            }
          ]}
          data={gallery.images}
          options={{
            pageSize: 10
          }}
        ></MaterialTable>
      </Grid>
    </Page>
  );
};
