import {Grid, Box, Button} from '@material-ui/core';
import _ from 'lodash';
import React, {useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {ApiContext} from '../../contexts/ApiContextProvider';
import {AppContext} from '../../contexts/AppContextProvider';
import {Gallery} from '../../shared/api-models';
import Page from '../../components/Page';
import MaterialTable from 'material-table';
import { getConfig } from '../../clientConfig';

const config = getConfig();

export default function Galleries() {
  const [loading, setLoading] = useState(true);
  const [galleries, setGalleries] = useState<Gallery[]>([]);

  const app = useContext(AppContext);
  const api = useContext(ApiContext);

  const handleRefresh = async (): Promise<any> => {
    setLoading(true);
    return api.galleries
      .getGalleries()
      .then((data) => setGalleries(_.orderBy(data, (x) => x.updatedAt, 'desc')))
      .catch((error) => app.setAlert(error.message, 'error'))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (api) {
      handleRefresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  const columns = [
    {
      title: 'Name',
      field: 'name',
      render: (row: Gallery) => (
        <Link to={`/${row.premium ? 'purchase' : 'gallery'}/${row.id}`}>
          {row.id.includes('users-') && 'User/'}
          {row.name}
        </Link>
      )
    },
    {
      title: 'Updated',
      field: 'updatedAt',
      render: (row: Gallery) => new Date(row.updatedAt).toLocaleString()
    }
  ];

  if (config.uploadEnabled && app.claims.groups.includes('Admin')) {
    columns.push({
      title: '',
      field: 'updatedAt',
      render: (row: Gallery) => (
        <Box marginRight={2}>
          <Button component={Link} to={`/edit/${row.id}`} variant="contained" color="secondary">
            Edit
          </Button>
        </Box>
      )
    });
  }

  return (
    <Page title="Galleries" onRefresh={handleRefresh} loading={loading}>
      <Grid item xs={12}>
        <MaterialTable
          title=""
          components={{
            Container: (props) => <Box>{props.children}</Box>
          }}
          columns={columns}
          data={galleries}
          options={{
            pageSize: 10
          }}
        ></MaterialTable>
      </Grid>
    </Page>
  );
}
