import {AppPrices, IAppPrices} from './shared/api-config';

const pkgInfo = require('../package.json');

interface Config {
  name: string;
  region: string;
  stage: string;
  apiUrl: string;
  cognito: {
    userPoolId: string;
    clientId: string;
  };
  manageContentUrl: string;
  manageUsersUrl: string;
  logging: {
    level: 'DEBUG' | 'INFO' | 'ERROR';
    accessKeyId: string;
    secretAccessKey: string;
    logGroup: string;
  };
  version: string;
  uploadEnabled: boolean;
  prices: IAppPrices;
}

const getConfig = (): Config => {
  const stage = process.env.REACT_APP_STAGE;

  const defaults = {
    name: '@Southernstyle_chick',
    region: 'us-east-1',
    stage,
    logging: {
      level: 'DEBUG' as any,
      accessKeyId: 'AKIAUFRRDYI2UQPZAQ77',
      secretAccessKey: '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'
    },
    version: pkgInfo.version,
    uploadEnabled: false
  };

  if (stage === 'dev' || stage === 'local') {
    const userPoolId = 'us-east-1_7LQmMnQy6';
    const result: Config = {
      ...defaults,
      apiUrl: 'https://dev.southernstylechick.com',
      cognito: {
        userPoolId,
        clientId: '6g0ag5rv7v9ksec9ktji6rqi48'
      },
      manageContentUrl: `https://s3.console.aws.amazon.com/s3/buckets/ssc-${stage}-content?region=${defaults.region}&prefix=Basic/&showversions=false`,
      manageUsersUrl: `https://console.aws.amazon.com/cognito/users/?region=${defaults.region}#/pool/${userPoolId}/users?_k=0mvmkk`,
      logging: {
        ...defaults.logging,
        logGroup: `/ssc-web-${stage}`
      },
      prices: AppPrices.dev
    };

    if (stage === 'local') {
      result.apiUrl = 'http://localhost:2000';
    }

    return result;
  } else if (stage === 'prod') {
    const userPoolId = 'us-east-1_aMkq98PSE';
    return {
      ...defaults,
      apiUrl: 'https://southernstylechick.com',
      cognito: {
        userPoolId,
        clientId: '6g9btn248olclsksgdmlb5909o'
      },
      manageContentUrl: `https://s3.console.aws.amazon.com/s3/buckets/ssc-${stage}-content?region=${defaults.region}&prefix=Basic/&showversions=false`,
      manageUsersUrl: `https://console.aws.amazon.com/cognito/users/?region=${defaults.region}#/pool/${userPoolId}/users?_k=0mvmkk`,
      logging: {
        ...defaults.logging,
        logGroup: `/ssc-web-${stage}`
      },
      prices: AppPrices.prod
    };
  } else {
    throw new Error('Unknown config: ' + stage);
  }
};

export {getConfig};
