import React, { useEffect, useState} from 'react';
import AmplifyService from '../services/AmplifyService';
import {getLogger} from '../services/LoggingService';
import {Auth, Hub} from 'aws-amplify';
import {AppSnackbar} from '../components/AppSnackbar';
import {AppAlert} from '../models/AppAlert';
import {Color} from '@material-ui/lab/Alert';
import {IClaims, SubscriptionStatus} from '../shared/api-models';

const logger = getLogger('AppContextProvider');

export interface IAppContext {
  /**
   * Will be null if user not authenticated.
   */
  claims: IClaims;
  status?: SubscriptionStatus;
  newMailCount: number;
  setAlert: (message: string, type: Color) => void;
  setStatus: (status: SubscriptionStatus) => void;
  logout: () => void;
}

export const AppContext = React.createContext(null as IAppContext);

export function AppContextProvider(props: {children: any}) {
  const [alert, setAlert] = useState<AppAlert>(null);
  const [claims, setClaims] = useState<IClaims>();
  const [status, setStatus] = useState<SubscriptionStatus>();
  const [newMailCount, setNewMailCount] = useState(0);

  const getClaims = () => {
    AmplifyService.getClaims().then(setClaims);
  };

  useEffect(() => {
    try {
      AmplifyService.configureAmplify();
      if (window.location.href.indexOf('?code=') > -1) {
        logger.debug('Processing authentication.');
      }

      Hub.listen('auth', async (data: any) => {
        logger.debug('authListener event', data.payload.event, data.payload?.data?.username);
        switch (data.payload.event) {
          case 'signIn':
            getClaims();
            break;
          case 'signIn_failure':
            logger.error('signIn_failure', JSON.stringify(data.payload));
            break;
          default:
            break;
        }
      });

      getClaims();
    } catch (error) {
      logger.error(`Configuration Failed: ${error.message}`, error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = () => {
    Auth.signOut().then(() => {
      // Do full redirect to clear out any context, etc.
      window.location.href = window.location.origin;
    });
  };

  const ctx: IAppContext = {
    claims,
    status,
    newMailCount,
    setAlert: (message: string, type: Color) => setAlert(new AppAlert(message, type)),
    setStatus,
    logout
  };

  return (
    <AppContext.Provider value={ctx}>
      <AppSnackbar alert={alert} clear={() => ctx.setAlert('', 'success')} />
      {props.children}
    </AppContext.Provider>
  );
}
