export interface IAppPrices {
  month: string;
  quarter: string;
  year: string;
  vip: string;
}

export const AppPrices: {dev: IAppPrices, prod: IAppPrices} = {
  dev: {
    month: 'price_1JueeHEHechAR2hwT8UgQucn',
    quarter: 'price_1JueOSEHechAR2hwEGuYdNBY',
    year: 'price_1JueOwEHechAR2hwnk6UWdgG',
    vip: 'price_1KJKuVEHechAR2hwrofiLqkV'
  },
  prod: {
    month: 'price_1JtBvUEHechAR2hwHNx06yvD',
    quarter: 'price_1JueqVEHechAR2hweWyM3Z7E',
    year: 'price_1JueqeEHechAR2hwv7J4CEbI',
    vip: 'price_1KJKvaEHechAR2hwzWp9ZNyf'
  }
};
