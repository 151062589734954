import { Button, Grid, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { getIn, useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PersonIcon from '@material-ui/icons/Person';
import { getLogger } from "../services/LoggingService";
import { AppMessage, SendMessageRequest } from "../shared/api-models";
import { AppTextField } from "../components/AppInputs";
import Page from "../components/Page";
import { ApiContext } from "../contexts/ApiContextProvider";
import { AppContext } from "../contexts/AppContextProvider";
import _ from "lodash";
import { LogoIcon } from "../components/LogoIcon";

const logger = getLogger("Messages");
export function Messages() {
  let messagesEndRef = useRef(null);

  const [readOnly, setReadOnly] = useState(false);
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState<AppMessage[]>([]);

  const app = useContext(AppContext);
  const api = useContext(ApiContext);

  const handleRefresh = async (): Promise<any> => {
    setLoading(true);
    return api.messages.getMessages()
      .then(data => {
        setMessages(data);
      })
      .catch(error => app.setAlert(error.message, "error"))
      .finally(()=>{
        setLoading(false);
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      });
  }

  useEffect(() => {
    handleRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { handleBlur, setFieldValue, handleSubmit, values, errors, touched } = useFormik<SendMessageRequest>({
    initialValues: {
      text: "",
    },
    // validationSchema: NoteRequestSchema,
    onSubmit: async (values) => {
      try {
        setReadOnly(true);
        console.log(values)
        const result = await api.messages.sendMessage(values);
        setMessages([...messages as any, result]);
        setFieldValue("text", "", false);
        app.setAlert("Message sent", "success");
      } catch (e) {
        logger.error(e.message);
        app.setAlert(e.message, "error");
      } finally {
        setReadOnly(false);
      }
    },
  });

  /**
   * Returns error text if touched. Works with nested objects.
   */
  const getError = (name: string): string => {
    return getIn(touched, name) && getIn(errors, name)
      ? getIn(errors, name)
      : "";
  };

  const renderMessage = (message: AppMessage, index: number) => {
    const isUser = message.direction === "out";
    const text = _.trim(message.text || "");
    const content = text.split("\n").map(x=>(<>{x}<br /></>))
    return <ListItem key={index} selected={!isUser}>
      <ListItemIcon>
        {isUser ? <PersonIcon /> : <LogoIcon color={"primary"} />}
      </ListItemIcon>
      <ListItemText secondary={`${new Date(message.createdAt).toLocaleString()} ${isUser ? "You" : "Her"}`}>
        {content}
      </ListItemText>
    </ListItem>
  }

  const formProps = {
    getError,
    getValue: (name: string) => getIn(values, name),
    handleBlur,
    setFieldValue,
    readOnly
  }

  return (
    <Page title="Messages" onRefresh={handleRefresh} loading={loading}>
      <Grid item xs={12}>
        <List >
          {messages.map((x, i) => (renderMessage(x, i)))}
        </List>

        <AppTextField
          name="text"
          label="Message"
          multiline={true}
          {...formProps}
        />
        <Button
          variant="contained"
          color="primary"
          className="submit-button"
          onClick={() => handleSubmit()}
          disabled={readOnly}
        >Send</Button>
        <div ref={messagesEndRef} />
      </Grid>
    </Page>
  );
}