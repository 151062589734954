import { ApiClientBase } from "./ApiClientBase";
import { getLogger } from "../LoggingService";
import { MessageApi } from "../../shared/api-interfaces";
import { AppMessage, SendMessageRequest } from "../../shared/api-models";

export class MessagesApiClient extends ApiClientBase implements MessageApi {

  constructor(apiUrl: string) {
    super(apiUrl, getLogger("MessagesApiClient"), true)
  }

  async getMessages(): Promise<AppMessage[]> {
    const result = await this.get("/api/messages")
    const data: AppMessage[] = await result.json();
    return data;
  }

  async sendMessage(req: SendMessageRequest): Promise<AppMessage> {
    const result = await this.post("/api/messages", req)
    const data: AppMessage = await result.json();
    return data;
  }
}