import React, { useContext, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { ApiContext } from "../../contexts/ApiContextProvider";
import { AppContext } from "../../contexts/AppContextProvider";
import queryString from 'query-string';
import { Grid } from "@material-ui/core";
import Page from "../../components/Page";


export default function BillingSuccess() {

  const [loading, setLoading] = useState(true);

  const app = useContext(AppContext);
  const api = useContext(ApiContext);

  useEffect(() => {
    if (!api) {
      return;
    }

    const load = async () => {
      setLoading(true);
      try {
        const parsed = queryString.parse(window.location.search) as { sessionId: string };
        if (!parsed.sessionId) {
          throw new Error("sessionId not found");
        }
        const checkoutResult = await api.billing.checkout({
          sessionId: parsed.sessionId
        });
        app.setStatus(checkoutResult.status);
      } catch (error) {
        app.setAlert(error.message, "error");
      } finally {
        setLoading(false);
      }
    }
    load();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  if (app.status === "active") {
    app.setAlert("Your payment succeeded", "success");
    return <Redirect to="/" />
  }

  return (
    <Page title="Processing Checkout" loading={loading}>
      <Grid item xs={12}>
        <p>{app.status || "Processing"}</p>
      </Grid>
    </Page>
  );
};