import {
    ClickAwayListener,
    FormControl,
    Grid,
    GridSize,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInputProps,
    Select,
    Switch,
    TextField,
    Tooltip,
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useState } from "react";
import InfoIcon from '@material-ui/icons/Info';

const style: any = {
    margin: "10px 5px 10px 5px",
};

export interface IOption {
    label: string;
    value: string | number;
}

interface IGridProps {
    xs?: GridSize;
    sm?: GridSize;
    md?: GridSize;
    lg?: GridSize;
    xl?: GridSize;
}

interface IProps extends IGridProps {
    name: string;
    label: string;
    helperText?: string;
    readOnly?: boolean;

    // values: any;

    getError: (name: string) => string;
    getValue: (name: string) => any;
    setFieldValue: (name: string, value: any) => void;
    handleBlur: (e: any) => void;
}


interface ISelectProps extends IProps {
    options: IOption[];
}
interface IAutoCompleteProps extends IProps {
    options: string[];
}

export function AppToolTip(props: { text: string, placement: "left" | "right" }) {
    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
                title={props.text}
                placement={props.placement}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener>

                <IconButton onClick={handleTooltipOpen}>
                    <InfoIcon color={"primary"} />
                </IconButton>
            </Tooltip>
        </ClickAwayListener>
    )
}


interface IFieldProps extends IProps {
    multiline?: boolean;
    type: "text" | "number";
    isPercent?: boolean;
}
export function AppField(props: IFieldProps) {


    const handleChange = (val: string) => {
        if (props.type === "number") {
            props.setFieldValue(props.name, val === "" ? null : parseFloat(val));
        } else {
            props.setFieldValue(props.name, val || "");
        }
    }

    const variant = "outlined";
    const error = props.getError(props.name);

    const inputProps: Partial<OutlinedInputProps> = {
        startAdornment: props.isPercent && <InputAdornment position="start">%</InputAdornment>,
        endAdornment: props.helperText && <InputAdornment position="end"><AppToolTip text={props.helperText} placement={"left"} /></InputAdornment>,
    }

    return (
        <Grid
            item
            xs={props.xs}
            sm={props.sm}
            md={props.md}
            lg={props.lg}
            xl={props.xl}
        >
            <TextField
                label={props.label}
                name={props.name}
                value={props.getValue(props.name) || ""}
                variant={variant}
                error={error ? true : false}
                helperText={error}
                onChange={(e) => handleChange(e.target.value)}
                onBlur={props.handleBlur}
                fullWidth={true}
                style={style}
                multiline={props.multiline}
                disabled={props.readOnly}
                type={props.type}
                InputProps={inputProps}
            />
        </Grid>
    );
}

export function AppTextField(props: IProps & { multiline?: boolean; }) {
    return (<AppField type="text" {...props} />)
}

export function AppNumberField(props: IProps & { isPercent?: boolean; }) {
    return (<AppField type="number" {...props} />)
}

export function AppSwitch(props: IProps) {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.setFieldValue(props.name, event.target.checked);
    };

    return (
        <Grid
            item
            xs={props.xs}
            sm={props.sm}
            md={props.md}
            lg={props.lg}
            xl={props.xl}
        >
            <Switch
                checked={props.getValue(props.name) || false}
                onChange={handleChange}
                color="primary"
                name={props.name}
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
        </Grid>
    );
}

export function AppSelectField(props: ISelectProps) {

    const error = props.getError(props.name);
    const labelId = `${props.name}-label`;

    return (
        <Grid
            item
            xs={props.xs}
            sm={props.sm}
            md={props.md}
            lg={props.lg}
            xl={props.xl}
        >
            <FormControl variant="outlined" fullWidth={true}>
                <InputLabel id={labelId}>{props.label}</InputLabel>
                <Select
                    labelId={labelId}
                    name={props.name}
                    error={error ? true : false}
                    onChange={(e, data) => props.setFieldValue(props.name, e.target.value)}
                    onBlur={props.handleBlur}
                    label={props.label}
                    fullWidth={true}
                    style={style}
                    value={props.getValue(props.name) || ""}
                    disabled={props.readOnly}
                >
                    <MenuItem value="" key={0}>
                        <em>None</em>
                    </MenuItem>
                    {props.options.map((o, index) => (
                        <MenuItem value={o.value} key={index + 1}>
                            {o.label}
                        </MenuItem>
                    ))}
                </Select>
                {error ? <div>{error}</div> : null}
            </FormControl>
        </Grid>
    );
}

export class AppAutoCompleteField extends React.Component<IAutoCompleteProps, {}> {

    handleChange(val: string) {
        const v = val || "";
        this.setState({ value: v });
        this.props.setFieldValue(this.props.name, v);
    }

    render() {

        const name = this.props.name;
        const error = this.props.getError(name);

        if (this.props.readOnly) {
            // TODO,HACK: This component doesn't show existing data - need to fix
            return <AppTextField
                name={name}
                label={this.props.label}
                getValue={this.props.getValue}
                getError={this.props.getError}
                handleBlur={this.props.handleBlur}
                setFieldValue={this.props.setFieldValue}
                readOnly={this.props.readOnly}
                xs={6}
            />
        }

        return (
            <Grid
                item
                xs={this.props.xs}
                sm={this.props.sm}
                md={this.props.md}
                lg={this.props.lg}
                xl={this.props.xl}
            >
                <Autocomplete
                    freeSolo
                    options={this.props.options}
                    onChange={(e, v) => this.handleChange(v)}
                    onBlur={this.props.handleBlur}
                    fullWidth={true}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            name={name}
                            label={this.props.label}
                            value={this.props.getValue(this.props.name)}
                            variant={"outlined"}
                            error={error ? true : false}
                            helperText={error}
                            onChange={(e) => this.handleChange(e.target.value)}
                            onBlur={this.props.handleBlur}
                            fullWidth={true}
                            style={style}
                        />
                    )}
                // disabled={this.props.readOnly}
                />
            </Grid>
        );
    }
}


export interface IReadonlyTextProps extends IGridProps {
    name: string;
    label: string;
    value: string;
    multiline?: boolean;
}
export class AppReadOnlyText extends React.Component<IReadonlyTextProps, {}> {
    render() {

        if (!this.props.value) {
            return null;
        }

        return (
            <Grid
                item
                xs={this.props.xs}
                sm={this.props.sm}
                md={this.props.md}
                lg={this.props.lg}
                xl={this.props.xl}
            >
                <TextField
                    label={this.props.label}
                    name={this.props.name}
                    value={this.props.value}
                    variant="outlined"
                    fullWidth={true}
                    style={style}
                    multiline={this.props.multiline}
                    disabled={true}
                    className="high-contrast"
                />
            </Grid>
        )
    }
}
