import {Auth} from 'aws-amplify';
import {Logger} from '../../models/Logger';

export class ApiClientBase {
  constructor(protected apiUrl: string, protected logger: Logger, private useAuth: boolean) {}

  protected async get(endpoint: string): Promise<Response> {
    return await this.request(endpoint, 'GET');
  }

  protected async post(endpoint: string, body?: any): Promise<Response> {
    return await this.request(endpoint, 'POST', body);
  }

  protected async put(endpoint: string, body?: any): Promise<Response> {
    return await this.request(endpoint, 'PUT', body);
  }

  protected async delete(endpoint: string): Promise<Response> {
    return await this.request(endpoint, 'DELETE');
  }

  private async request(endpoint: string, method: string, body?: any): Promise<Response> {
    const params: RequestInit = {
      method: method,
      body: body ? JSON.stringify(body) : undefined,
      headers: {
        Authorization: this.useAuth ? `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` : undefined
      }
    };

    const result = await fetch(this.apiUrl + endpoint, params);

    if (result.status < 200 || result.status > 299) {
      const response = {status: result.status, body: await result.text()};
      this.logger.error(`${method} ${endpoint} request failed`, response);

      if (response.status === 400) {
        const errors = JSON.parse(response.body);
        const message =
          errors.message ||
          Object.keys(errors)
            .map((key) => `${errors[key].join(', ')}`)
            .join('; ');
        throw new Error(message);
      } else {
        throw new Error(`${response.status}: ${JSON.stringify(response.body)}`);
      }
    }
    return result;
  }
}
