import {CognitoIdToken} from 'amazon-cognito-identity-js';
import {Auth} from 'aws-amplify';
import {ISignUpConfig} from 'aws-amplify-react/lib-esm/Auth/SignUp';
import {getConfig} from '../clientConfig';
import {IClaims} from '../shared/api-models';
import {getLogger} from './LoggingService';

const logger = getLogger('AmplifyService');

const config = getConfig();

/**
 * Provides helpers for working with Amplify
 */
export default abstract class AmplifyService {
  public static configureAmplify(): void {
    const options = {
      Analytics: {
        disabled: true
      },
      Auth: {
        region: config.region,
        userPoolId: config.cognito.userPoolId,
        userPoolWebClientId: config.cognito.clientId,
        mandatorySignIn: false
      }
    };

    Auth.configure(options.Auth);
  }

  public static async sdkCredentials(): Promise<any> {
    try {
      const credentials = await Auth.currentUserCredentials();
      return Auth.essentialCredentials(credentials);
    } catch (error) {
      logger.error(error);
      throw error;
    }
  }

  public static async isAuthenticated(): Promise<boolean> {
    try {
      await Auth.currentAuthenticatedUser();
      return true;
    } catch (error) {
      logger.error('isAuthenticated()', error);
      return false;
    }
  }

  public static async getUserId(): Promise<string> {
    const user = (await Auth.currentSession()).getIdToken().decodePayload();

    let email = null;
    if (user.identities && user.identities.length > 0) {
      // Federated Auth
      email = user.identities[0].userId;
    } else {
      // User Pool Auth
      email = user.email;
    }

    return email;
  }

  /**
   * Initiates federated sign-in. When custom provider is passed in, will redirect to IDP without showing hosted ui.
   * @param customProvider Cognito Identity Provider Id
   */
  public static federatedLogin(customProvider?: string) {
    if (customProvider) {
      Auth.federatedSignIn({
        customProvider
      });
    } else {
      Auth.federatedSignIn();
    }
  }

  public static async getAccessJwtToken(): Promise<string> {
    const session = Auth.currentSession();
    const accessToken = (await session).getAccessToken();
    const jwtToken = accessToken.getJwtToken();
    return jwtToken;
  }

  public static async getIdToken(): Promise<CognitoIdToken> {
    try {
      const session = await Auth.currentSession();
      const idToken = session.getIdToken();
      return idToken;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  }

  public static async getClaims(): Promise<IClaims> {
    try {
      const session = await Auth.currentSession();
      const idToken = session.getIdToken();
      const payload = idToken.decodePayload();
      return {
        username: payload['cognito:username'],
        email: payload.email,
        groups: payload['cognito:groups'] || []
      };
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}

//https://github.com/aws-amplify/amplify-js/blob/main/packages/aws-amplify-react-native/src/AmplifyTheme.ts
export const amplifyTheme = {
  button: {backgroundColor: '#800020'},
  a: {color: '#800020'}
};

export const amplifyConfig: ISignUpConfig = {
  hiddenDefaults: ['phone_number']
};
