import Typography from '@material-ui/core/Typography';
import { Box, Container, LinearProgress, makeStyles, Paper } from '@material-ui/core';
import AppNav from './AppNav';
import { Copyright } from './Copyright';
import PullToRefresh from 'react-simple-pull-to-refresh';
import React from 'react';
import { Authenticator } from 'aws-amplify-react';
import { amplifyConfig, amplifyTheme } from '../services/AmplifyService';

export function Title(props: { children: any }) {
  return (
    <Typography component="h2" variant="h6" color="primary" gutterBottom>
      {props.children}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    // marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  },
}));

interface IProps {
  title?: string;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;

  /**
   * When set, will use PullToRefresh
   */
  onRefresh?: () => Promise<any>;
  loading?: boolean;
  showAuthenticator?: boolean;
  children: any;
}
export default function Page(props: IProps) {
  const classes = useStyles();

  let content = (
    <Paper><Box p={2} m={2}>{props.loading ? <LinearProgress /> : props.children}</Box></Paper>
  )

  content = (
    <div className={classes.root}>
      <AppNav title={props.title} />
      <Container component="main" className={classes.main} maxWidth={props.maxWidth}>
        {content}
        {props.showAuthenticator && <Authenticator theme={amplifyTheme} signUpConfig={amplifyConfig} />}
      </Container>
      <footer className={classes.footer}>
        <Container maxWidth="sm">
          <Copyright />
        </Container>
      </footer>
    </div>
  )



  if (props.onRefresh) {
    content =
      (<PullToRefresh onRefresh={props.onRefresh}>
        {content}
      </PullToRefresh>)
  }

  return content;
}
