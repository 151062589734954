import red from '@material-ui/core/colors/red';
import { createMuiTheme } from '@material-ui/core/styles';
import createPalette from '@material-ui/core/styles/createPalette';

// A custom theme for this app
const theme = createMuiTheme({
  palette: createPalette({
    primary: {
      main: '#800020',
    },
    secondary: {
      main: '#008060',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#000',
    },
  }),
});

export default theme;
