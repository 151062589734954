import {ApiClientBase} from './ApiClientBase';
import {getLogger} from '../LoggingService';
import {BillingApi, Purchase, Session} from '../../shared/api-interfaces';
import {BillingConfig, UserInfo} from '../../shared/api-models';

export class BillingApiClient extends ApiClientBase implements BillingApi {
  constructor(apiUrl: string) {
    super(apiUrl, getLogger('BillingApiClient'), true);
  }

  async config(): Promise<BillingConfig> {
    const result = await this.get('/api/billing/config');
    return await result.json();
  }

  async subscribe(model: Purchase): Promise<Session> {
    const result = await this.post('/api/billing/subscribe', model);
    return await result.json();
  }

  async checkout(model: Session): Promise<UserInfo> {
    const result = await this.post('/api/billing/checkout', model);
    return await result.json();
  }

  async purchase(model: Purchase): Promise<Session> {
    const result = await this.post('/api/purchase', model);
    return await result.json();
  }

  async purchaseCheckout(model: Session): Promise<void> {
    await this.post('/api/purchase/checkout', model);
  }

  async portal(): Promise<{url: string}> {
    const result = await this.post('/api/billing/portal');
    return await result.json();
  }

  async status(): Promise<UserInfo> {
    const result = await this.get('/api/billing/status');
    return await result.json();
  }
}
