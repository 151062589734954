import {ApiClientBase} from './ApiClientBase';
import {getLogger} from '../LoggingService';
import {AdminApi} from '../../shared/api-interfaces';
import {AppConfig, UploadRequest} from '../../shared/api-models';

export class AdminApiClient extends ApiClientBase implements AdminApi {
  constructor(apiUrl: string) {
    super(apiUrl, getLogger('SettingsApiClient'), true);
  }

  async getSettings(): Promise<AppConfig> {
    const result = await this.get('/api/settings');
    return await result.json();
  }

  async postSettings(model: AppConfig): Promise<void> {
    await this.post('/api/settings', model);
  }

  async postContent(model: UploadRequest): Promise<{url: string}> {
    const result = await this.post('/api/content', model);
    return await result.json();
  }

  async deleteContent(key: string): Promise<void> {
    await this.delete(`/api/content?key=${encodeURIComponent(key)}`);
  }
}
