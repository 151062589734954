import { SvgIcon } from "@material-ui/core";
import React from "react";

export function LogoIcon(props: any) {
  return (
    <SvgIcon {...props} version="1.0" xmlns="http://www.w3.org/2000/svg"
      width="192.000000pt" height="192.000000pt" viewBox="0 0 192.000000 192.000000"
      preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,192.000000) scale(0.100000,-0.100000)"
        fill="#000000" stroke="none">
        <path d="M837 1655 c-174 -48 -305 -188 -328 -349 -4 -34 -12 -95 -17 -136
-12 -105 -50 -183 -159 -327 -98 -131 -132 -201 -140 -294 -7 -75 17 -157 61
-203 55 -59 66 -50 66 47 0 140 40 252 99 277 39 16 125 -1 209 -40 35 -17 75
-30 88 -30 46 0 114 129 114 216 0 24 -5 34 -21 39 -58 19 -149 141 -149 201
0 15 21 35 73 69 84 56 188 153 259 243 l50 62 37 -58 c39 -60 83 -102 132
-128 28 -14 29 -17 29 -92 0 -43 -7 -102 -16 -131 -18 -61 -78 -132 -136 -162
-37 -19 -38 -22 -33 -57 17 -99 75 -202 115 -202 10 0 52 16 93 36 86 40 174
55 213 34 13 -8 37 -39 52 -69 24 -49 27 -70 31 -173 2 -76 7 -118 14 -118 21
0 76 45 101 83 29 46 40 105 34 181 -6 67 -54 166 -152 308 -88 127 -112 192
-125 338 -15 159 -49 238 -139 322 -117 109 -301 155 -455 113z"/>
        <path d="M871 1067 c-22 -17 -41 -37 -41 -43 0 -24 52 -74 89 -85 32 -9 44 -8
76 7 31 14 75 64 75 84 0 3 -19 20 -41 37 -33 26 -46 30 -60 22 -13 -6 -25 -6
-38 0 -14 8 -27 4 -60 -22z m99 -52 c0 -15 -43 -12 -48 3 -3 10 4 13 22 10 15
-2 26 -7 26 -13z"/>
        <path d="M1270 445 c8 -11 22 -29 31 -40 9 -11 24 -32 34 -47 l17 -28 -38 17
c-131 55 -301 -20 -345 -154 l-11 -34 -28 58 c-46 99 -120 145 -230 146 -43 1
-76 -5 -97 -16 -18 -10 -33 -14 -33 -9 0 11 28 52 58 85 47 50 5 28 -50 -27
-107 -107 -144 -237 -98 -344 l22 -52 453 0 453 0 23 46 c44 93 21 210 -61
314 -32 41 -126 120 -100 85z"/>
      </g>

    </SvgIcon>
  );
}