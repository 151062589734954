import React from 'react';
import {Typography} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {getConfig} from '../clientConfig';

const {name, stage, version} = getConfig();

export function Copyright() {
  const stageInfo = stage !== 'prod' ? `| ${stage}` : '';
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {`Copyright © ${name} ${new Date().getFullYear()}`} | <Link to="/terms">Terms</Link> |{' '}
      <Link to="/privacy">Privacy</Link> | {version} {stageInfo}
    </Typography>
  );
}
