import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import ProtectedRoute from "./components/ProtectedRoute";
import Logout from "./pages/Logout";
import { AppContextProvider } from "./contexts/AppContextProvider";
import { GalleryViewer } from "./pages/galleries/GalleryViewer";
import { Messages } from "./pages/Messages";
import Galleries from "./pages/galleries/Galleries";
import { ApiContextProvider } from "./contexts/ApiContextProvider";
import Billing from "./pages/billing/Billing";
import BillingSuccess from "./pages/billing/Success";
import Cancelled from "./pages/billing/Cancelled";
import Terms from "./pages/Terms";
import { Settings } from "./pages/settings/Settings";
import Home from "./pages/Home";
import Privacy from './pages/Privacy';
import { GalleryEdit } from './pages/galleries/GalleryEdit';
import PurchaseSuccess from './pages/purchase/Success';
import { GalleryPurchase } from './pages/galleries/GalleryPurchase';

export default class App extends React.Component {
  render() {
    return (
      <Router>
        <AppContextProvider>
          <ApiContextProvider>
            {/* <AdultConsentWrapper> */}
              <Router>
                <Switch>
                  <Route path="/logout" component={Logout} />
                  <Route path="/terms" component={Terms} />
                  <Route path="/privacy" component={Privacy} />

                  {/* Requires Login */}

                  <ProtectedRoute path="/billing/cancelled" component={Cancelled} />
                  <ProtectedRoute path="/billing/success" component={BillingSuccess} />
                  <ProtectedRoute path="/billing" component={Billing} />

                  <ProtectedRoute path="/purchase/success" component={PurchaseSuccess} />

                  {/* Paid Routes */}
                  <ProtectedRoute path="/messages" paid={true} component={Messages} />
                  <ProtectedRoute path="/galleries" paid={true} component={Galleries} />
                  <ProtectedRoute path="/gallery/:id" paid={true} component={GalleryViewer} />
                  <ProtectedRoute path="/purchase/:id" paid={true} component={GalleryPurchase} />

                  {/* Admin Routes */}
                  <ProtectedRoute admin={true} path="/settings" component={Settings} />
                  <ProtectedRoute admin={true} path="/edit/:id" component={GalleryEdit} />

                  {/* Default Route */}
                  <Route path="/" component={Home} />
                </Switch>
              </Router>
            {/* </AdultConsentWrapper> */}
          </ApiContextProvider>
        </AppContextProvider>
      </Router>
    );
  }
}
