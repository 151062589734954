import {Box, Button, Grid, Typography} from '@material-ui/core';
import React, {useContext, useEffect, useState} from 'react';
import {ApiContext} from '../../contexts/ApiContextProvider';
import {AppContext} from '../../contexts/AppContextProvider';
import Page from '../../components/Page';
import {Stripe, loadStripe} from '@stripe/stripe-js';
import {BillingConfig} from '../../shared/api-models';
import _ from 'lodash';
import {getConfig} from '../../clientConfig';

const appConfig = getConfig();

export default function Billing() {
  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState<BillingConfig>();
  const [stripe, setStripe] = useState<Stripe>();

  const app = useContext(AppContext);
  const api = useContext(ApiContext);

  useEffect(() => {
    if (api) {
      const load = async () => {
        setLoading(true);
        try {
          const result = await api.billing.config();
          setConfig(result);
          const stripeObj = await loadStripe(result.publicKey);
          setStripe(stripeObj);
        } catch (error) {
          app.setAlert(error.message, 'error');
        } finally {
          setLoading(false);
        }
      };
      load();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  const subscribe = async (price: string) => {
    try {
      const model = await api.billing.subscribe({key: price});
      const result = await stripe.redirectToCheckout({
        sessionId: model.sessionId
      });
      if (result.error) {
        throw result.error;
      }
    } catch (error) {
      app.setAlert(error.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  const message = _.trim(config?.subscriptionRequiredMessage || '');
  const content = message.split('\n').map((x) => (
    <>
      {x}
      <br />
    </>
  ));

  return (
    <Page title="Subscribe" loading={loading}>
      <Grid item xs={12}>
        <p>{content}</p>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          className="submit-button"
          disabled={!stripe}
          onClick={() => subscribe(appConfig.prices.month)}
          style={{marginRight: '10px'}}
        >
          $8 / Month
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="submit-button"
          disabled={!stripe}
          onClick={() => subscribe(appConfig.prices.quarter)}
          style={{marginRight: '10px'}}
        >
          $24 / 3 Months
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="submit-button"
          disabled={!stripe}
          onClick={() => subscribe(appConfig.prices.year)}
          style={{marginRight: '10px'}}
        >
          $95 / Year
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Box marginTop={10} marginBottom={3}>
          <Typography variant="h5">VIP Access</Typography>
          <Typography>Unlimited access to all subscription and premium content!</Typography> 
        </Box>
        <Button
            variant="contained"
            color="primary"
            className="submit-button"
            disabled={!stripe}
            onClick={() => subscribe(appConfig.prices.vip)}
            style={{marginRight: '10px'}}
          >
            $400 / Month
          </Button>
      </Grid>
    </Page>
  );
}
