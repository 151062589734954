import {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {GalleryImages, Image} from '../../shared/api-models';
import {ApiContext} from '../../contexts/ApiContextProvider';
import {AppContext} from '../../contexts/AppContextProvider';
import {Grid, Box, Button} from '@material-ui/core';
import MaterialTable from 'material-table';
import Page from '../../components/Page';
import {getLogger} from '../../services/LoggingService';

const logger = getLogger('GalleryEdit');

interface IParams {
  id: string;
}

export const GalleryEdit = () => {
  const {id} = useParams() as IParams;
  const [gallery, setGallery] = useState<GalleryImages>(null);

  const {setAlert} = useContext(AppContext);
  const api = useContext(ApiContext);

  const refresh = () => {
    api.galleries
      .getImages(id)
      .then((data) => {
        setGallery(data);
      })
      .catch((error) => {
        setAlert(error.message, 'error');
      });
  };

  useEffect(() => {
    if (api && id) {
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, id]);

  const deleteFile = async (key: string) => {
    try {
      await api.settings.deleteContent(key);
      setAlert(`Deleted ${key}`, 'success');
      refresh();
    } catch (error) {
      logger.error('deleteFile: ' + error.message, error);
      setAlert(error.message, 'error');
    }
  };

  if (!gallery) {
    return null;
  }

  const uploadHandler = async (event) => {
    event.preventDefault();

    try {
      let fileObj: File = event.target.files[0];
      console.log(fileObj, fileObj.name);

      const postUrl = await api.settings.postContent({
        key: `${gallery.key}/${fileObj.name}`,
        contentType: fileObj.type
      });

      const response = await fetch(postUrl.url, {
        method: 'PUT',
        body: await fileObj.arrayBuffer(),
        headers: {
          // 'Content-Type': 'multipart/form-data',
          'Content-Type': fileObj.type
        }
      });
      if (response.status >= 400) {
        throw new Error('Bad response from server');
      }
      setAlert(`Uploaded ${fileObj.name}`, 'success');
      refresh();
    } catch (error) {
      logger.error('uploadHandler: ' + error.message, error);
      setAlert(error.message, 'error');
    }
  };

  return (
    <Page title={`Edit:  ${gallery.name}`}>
      <Grid item xs={12}>
        <Button variant="contained" component="label">
          Upload File
          <input type="file" hidden onChange={uploadHandler} />
        </Button>
      </Grid>
      <Grid item xs={12}>
        <MaterialTable
          title=""
          components={{
            Container: (props) => <Box>{props.children}</Box>
          }}
          columns={[
            {
              title: 'Name',
              field: 'name',
              render: (row: Image) => (
                <a target="_blank" rel="noreferrer" href={row.link}>
                  {row.name}
                </a>
              )
            },
            {
              title: '',
              field: 'name',
              render: (row: Image) => (
                <Box marginRight={2}>
                  <Button variant="contained" color="primary" onClick={() => deleteFile(`${gallery.key}/${row.name}`)}>
                    Delete
                  </Button>
                </Box>
              )
            }
          ]}
          data={gallery.images}
          options={{
            pageSize: 10
          }}
        ></MaterialTable>
      </Grid>
    </Page>
  );
};
