import React, { useEffect, useState } from "react";
import { GalleriesApi, MessageApi, BillingApi, AdminApi, ConfigApi } from "../shared/api-interfaces";
import { GalleriesApiClient } from "../services/api/GalleriesApiClient";
import { MessagesApiClient } from "../services/api/MessagesApiClient";
import { BillingApiClient } from "../services/api/BillingApiClient";
import { AdminApiClient } from "../services/api/SettingsApiClient";
import { ConfigApiClient } from '../services/api/ConfigApiClient';
import { getConfig } from '../clientConfig';

export interface IApiContext {
  config: ConfigApi;
  galleries: GalleriesApi;
  messages: MessageApi;
  billing: BillingApi;
  settings: AdminApi;
}
export const ApiContext = React.createContext(null as IApiContext);

const config = getConfig()

export function ApiContextProvider(props: { children: any }) {

  const [api, setApi] = useState<IApiContext>();

  useEffect(() => {
    if (config.apiUrl) {
      setApi({
        config: new ConfigApiClient(config.apiUrl),
        galleries: new GalleriesApiClient(config.apiUrl),
        messages: new MessagesApiClient(config.apiUrl),
        billing: new BillingApiClient(config.apiUrl),
        settings: new AdminApiClient(config.apiUrl),
      });
    }
  }, []);

  if (!api) {
    return null;
  }

  return (
    <ApiContext.Provider value={api}>
      {props.children}
    </ApiContext.Provider>
  );
}
