import React, { useContext, useMemo } from 'react';
import { fade, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import { useHistory } from 'react-router-dom';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import SettingsIcon from '@material-ui/icons/Settings';
import { AppContext } from '../contexts/AppContextProvider';
import { ApiContext } from '../contexts/ApiContextProvider';
import { getConfig } from '../clientConfig';

const config = getConfig();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  })
);

interface IProps {
  title?: string;
}

export default function AppNav(props: IProps) {
  const app = useContext(AppContext);
  

  const api = useContext(ApiContext);
  const history = useHistory();

  const classes = useStyles();

  const [settingsMenuAnchor, setSettingsMenuAnchor] = React.useState<null | HTMLElement>(null);
  const [userMenuAnchor, setUserMenuAnchor] = React.useState<null | HTMLElement>(null);

  const isSettingsMenuOpen = Boolean(settingsMenuAnchor);
  const isUserMenuOpen = Boolean(userMenuAnchor);

  const billingEnabled = useMemo(() => {
    return app.status !== 'none' && app.status !== 'prepaid';
  }, [app.status]);

  const messagesEnabled = useMemo(() => {
    return app.claims && !app.claims.groups.includes('Admin');
  }, [app.claims]);

  const settingsEnabled = useMemo(() => {
    return app.claims && app.claims.groups.includes('Admin');
  }, [app.claims]);

  const openBilling = async () => {
    if (billingEnabled) {
      try {
        const result = await api.billing.portal();
        window.location.href = result.url;
      } catch (error) {
        app.setAlert(error.message, 'error');
      }
    }
  };

  const settingsMenuId = 'primary-search-settings-menu';
  const userMenuId = 'primary-search-account-menu';
  const menuIcons = (
    <div>
      <IconButton aria-label="access photo carousel" color="inherit" onClick={() => history.push('/')}>
        <Badge color="primary">
          <ViewCarouselIcon />
        </Badge>
      </IconButton>
      {messagesEnabled && (
        <IconButton aria-label="access messages" color="inherit" onClick={() => history.push('/messages')}>
          <Badge badgeContent={app.newMailCount} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
      )}
      {settingsEnabled && (
        <IconButton
          aria-label="access settings"
          aria-controls={settingsMenuId}
          aria-haspopup="true"
          color="inherit"
          onClick={(event) => setSettingsMenuAnchor(event.currentTarget)}
        >
          <SettingsIcon />
        </IconButton>
      )}
      <IconButton
        edge="end"
        aria-label="account of current user"
        aria-controls={userMenuId}
        aria-haspopup="true"
        color="inherit"
        onClick={(event) => setUserMenuAnchor(event.currentTarget)}
      >
        <AccountCircle />
      </IconButton>
    </div>
  );

  const settingsMenu = () => (
    <Menu
      anchorEl={settingsMenuAnchor}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={settingsMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isSettingsMenuOpen}
      onClose={() => setSettingsMenuAnchor(null)}
    >
      <MenuItem
        onClick={() => {
          window.location.href = config.manageContentUrl;
        }}
      >
        Files
      </MenuItem>
      <MenuItem
        onClick={() => {
          window.location.href = config.manageUsersUrl;
        }}
      >
        Users
      </MenuItem>
      <MenuItem onClick={() => history.push('/settings')}>Settings</MenuItem>
    </Menu>
  );

  const userMenu = () => (
    <Menu
      anchorEl={userMenuAnchor}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={userMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isUserMenuOpen}
      onClose={() => setUserMenuAnchor(null)}
    >
      <MenuItem disabled>{app.claims.username}</MenuItem>
      {billingEnabled && <MenuItem onClick={openBilling}>Manage Billing</MenuItem>}
      <MenuItem onClick={() => app.logout()}>Log Out</MenuItem>
    </Menu>
  );

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          {props.title && (
            <Typography className={classes.title} variant="h6" noWrap>
              {props.title}
            </Typography>
          )}
          <div className={classes.grow} />
          {app.claims && menuIcons}
        </Toolbar>
      </AppBar>
      {settingsEnabled && settingsMenu()}
      {app.claims && userMenu()}
    </div>
  );
}
