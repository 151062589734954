import { ApiClientBase } from "./ApiClientBase";
import { getLogger } from "../LoggingService";
import { ConfigApi } from "../../shared/api-interfaces";
import { AppConfigPublic } from "../../shared/api-models";

export class ConfigApiClient extends ApiClientBase implements ConfigApi {

  constructor(apiUrl: string) {
    super(apiUrl, getLogger("ConfigApiClient"), false)
  }

  async getConfig(): Promise<AppConfigPublic> {
    const result = await this.get("/api/config");
    return await result.json();
  };
}