import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GalleryImages, Image } from '../../shared/api-models';
import { ApiContext } from '../../contexts/ApiContextProvider';
import { AppContext } from '../../contexts/AppContextProvider';
import AppNav from '../../components/AppNav';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import ReactImageGallery from 'react-image-gallery';
import { useRef } from 'react';

interface IParams {
  id: string;
}

export const GalleryViewer = () => {
  const _imageGallery = useRef<ReactImageGallery>();

  const { id } = useParams() as IParams;
  const [gallery, setGallery] = useState<GalleryImages>(null);

  const [state, setState] = useState({
    currentIndex: 0,
    activeVideo: '',
    showGalleryPlayButton: true,
    showGalleryFullscreenButton: true,
    autoPlay: false,
    autoRestart: false,
  });

  const app = useContext(AppContext);
  const api = useContext(ApiContext);

  useEffect(() => {
    if (api && id) {
      api.galleries
        .getImages(id)
        .then((data) => {
          setGallery(data);
        })
        .catch((error) => {
          app.setAlert(error.message, 'error');
        });
    }
  }, [app, api, id]);

  if (!gallery) {
    return null;
  }

  const _onSlide = (index: number) => {
    if (state.activeVideo) {
      const vid = document.getElementById(state.activeVideo);
      if (vid) {
        (vid as any).pause();
      }

      setState((prev) => ({
        ...prev,
        currentIndex: index,
        activeVideo: '',
        showGalleryPlayButton: true,
        showGalleryFullscreenButton: true,
      }));
    } else {
      const current = gallery.images[index];
      setState((prev) => ({
        ...prev,
        currentIndex: index,
        showGalleryPlayButton: current.isVideo ? false : true,
        showGalleryFullscreenButton: current.isVideo ? false : true,
      }));

      if (current.isVideo && state.autoPlay) {
        const vid = document.getElementById(current.name);
        if (vid) {
          (vid as any).play();
        }
      }
    }
  };

  const _renderVideo = (item: ReactImageGalleryItem) => {
    const image: Image = item as any;
    return (
      <div>
        <video
          id={image.name}
          className="image-gallery-image"
          controls
          onPlay={() => {
            setState((prev) => ({ ...prev, activeVideo: image.name }));

            if (state.autoPlay) {
              setState((prev) => ({ ...prev, autoRestart: true }));
              _imageGallery.current.pause();
            }
          }}
          onEnded={(e) => {
            if (state.autoRestart) {
              setState((prev) => ({ ...prev, autoRestart: false }));
              _imageGallery.current.play();
              let next = state.currentIndex + 1;
              if (next === gallery.images.length) {
                next = 0;
              }
              _imageGallery.current.slideToIndex(next);
            }
          }}
        >
          <source src={item.original}></source>
        </video>
      </div>
    );
  };

  const _onPlay = () => {
    setState((prev) => ({
      ...prev,
      autoPlay: true,
    }));
  };

  const _onPause = () => {
    setState((prev) => ({
      ...prev,
      autoPlay: false,
    }));
  };

  return (
    <React.Fragment>
      <AppNav title={gallery.name} />
      <ImageGallery
        ref={_imageGallery}
        items={gallery.images.map((x) => ({
          name: x.name,
          original: x.link,
          renderItem: x.isVideo ? _renderVideo.bind(this) : undefined,
        }))}
        startIndex={state.currentIndex}
        showIndex={true}
        showFullscreenButton={state.showGalleryFullscreenButton}
        showPlayButton={state.showGalleryPlayButton}
        showThumbnails={false}
        lazyLoad={true}
        onSlide={_onSlide.bind(this)}
        onPlay={_onPlay.bind(this)}
        onPause={_onPause.bind(this)}
      />
    </React.Fragment>
  );
};
