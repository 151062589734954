import React from 'react';
import ReactDOM from 'react-dom';
import "@aws-amplify/ui/dist/style.css";
import { ThemeProvider } from '@material-ui/core/styles';
import App from './App';
import theme from './theme';
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "react-image-gallery/styles/css/image-gallery.css";
import { CssBaseline } from '@material-ui/core';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <App />
  </ThemeProvider>,
  document.querySelector('#root'),
);
