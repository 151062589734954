import {useContext, useEffect, useMemo} from 'react';
import {Redirect, Route, RouteProps, useHistory} from 'react-router-dom';
import {AppContext} from '../contexts/AppContextProvider';
import {
  ConfirmSignIn,
  ConfirmSignUp,
  ForgotPassword,
  RequireNewPassword,
  SignIn,
  SignUp,
  VerifyContact,
  withAuthenticator
} from 'aws-amplify-react';
import {ApiContext} from '../contexts/ApiContextProvider';
import AmplifyService, {amplifyConfig, amplifyTheme} from '../services/AmplifyService';

interface IProps extends RouteProps {
  path: string;
  paid?: boolean;
  admin?: boolean;
}

function ProtectedRoute(props: IProps) {
  const app = useContext(AppContext);
  const api = useContext(ApiContext);

  const history = useHistory();
  const {admin} = props;

  useEffect(() => {
    if (admin) {
      (async () => {
        // Check user access
        const claims = await AmplifyService.getClaims();
        if (!claims.groups.includes('Admin')) {
          history.replace('/');
          return;
        }
      })();
    }
  }, [history, admin]);

  useEffect(() => {
    if (app.claims && !app.status) {
      api.billing
        .status()
        .then((data) => {
          app.setStatus(data.status);
        })
        .catch((e) => {
          app.setAlert(e.message, 'error');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.claims]);

  const hasSubscription = useMemo(() => {
    return app.status === 'active' || app.status === 'prepaid';
  }, [app.status]);

  if (props.paid) {
    if (!app.status) {
      // Wait for status update
      return null;
    }

    if (!hasSubscription) {
      return <Redirect to="/billing" />;
    }
  }

  return <Route path={props.path} component={props.component} />;
}

export default withAuthenticator(
  ProtectedRoute,
  false,
  [
    <SignIn />,
    <ConfirmSignIn />,
    <VerifyContact />,
    <SignUp signUpConfig={amplifyConfig} />,
    <ConfirmSignUp />,
    <ForgotPassword />,
    <RequireNewPassword />
  ],
  null,
  amplifyTheme
);
