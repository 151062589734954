import Page from '../components/Page';
import ReactMarkdown from 'react-markdown';
import { getConfig } from '../clientConfig';

// https://github.com/appdotnet/template-terms-of-service

const {name} = getConfig();

export default function Privacy() {

  const termsLink = `${window.location.origin}/terms`;
  const supportEmail = 'lsw-apps@outlook.com';

  const markdown = `
# **${name}** PRIVACY POLICY

Last Revised: **6/26/2021**

Our privacy policy applies to information we collect when you use or access our website, application, or just interact with us. We may change this privacy policy from time to time. Whenever we make changes to this privacy policy, the changes are effective **IMMEDIATELY** after we post the revised privacy policy (as indicated by revising the date at the top of our privacy policy). We encourage you to review our privacy policy whenever you access our services to stay informed about our information practices and the ways you can help protect your privacy.

## Collection of Information

### Information You Provide to Us

We collect information you provide directly to us. For example, we collect information when you participate in any interactive features of our services, fill out a form, request customer support, provide any contact or identifying information or otherwise communicate with us. The types of information we may collect include your name, email address, postal address, and other contact or identifying information you choose to provide.

### Information We Collect Automatically When You Use the Services

When you access or use our services, we automatically collect information about you, including:

* **Log Information**: We log information about your use of our services, including the type of browser you use, access times, pages viewed, your IP address and the page you visited before navigating to our services.
* **Device Information**: We collect information about the computer you use to access our services, including the hardware model, and operating system and version.

For more details about how we collect information, including details about cookies and how to disable them, please see "Your Information Choices" below.

## Use of Information

We use information about you for various purposes, including to:

* Provide, maintain and improve our services;
* Provide services you request, process transactions and to send you related information;
* Send you technical notices, updates, security alerts and support and administrative messages;
* Respond to your comments, questions and requests and provide customer service;
* Communicate with you about news and information related to our service;
* Monitor and analyze trends, usage and activities in connection with our services; and
* Personalize and improve our services.

By accessing and using our services, you consent to the processing and transfer of your information in and to the United States and other countries.

## Sharing of Information

We may share personal information about you as follows:

* If we believe disclosure is reasonably necessary to comply with any applicable law, regulation, legal process or governmental request;
* To enforce applicable user agreements or policies, including our Terms of Service **[${termsLink}](${termsLink})**; and to protect us, our users or the public from harm or illegal activities;
* If we notify you through our services (or in our privacy policy) that the information you provide will be shared in a particular manner and you provide such information.

We may also share aggregated or anonymized information that does not directly identify you.

## Security

We take reasonable measures to help protect personal information from loss, theft, misuse and unauthorized access, disclosure, alteration and destruction.

## Your Information Choices

### Cookies

Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove or reject browser cookies. Please note that if you choose to remove or reject cookies, this could affect the availability and functionality of our services.

## Contact Us

If you have any questions about this privacy policy, please contact us at:  **${supportEmail}**.

    `;
  return (
    <Page title="Privacy Policy">
      <ReactMarkdown children={markdown} />
    </Page>
  );
}
