import { Button, Grid } from "@material-ui/core";
import { getIn, useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { getLogger } from "../../services/LoggingService";
import { AppConfig } from "../../shared/api-models";
import { AppTextField } from "../../components/AppInputs";
import Page from "../../components/Page";
import { ApiContext } from "../../contexts/ApiContextProvider";
import { AppContext } from "../../contexts/AppContextProvider";
import { AppConfigSchema } from "../../shared/api-validation";

const logger = getLogger("Settings");

export function Settings() {

  const [readOnly, setReadOnly] = useState(false);
  const [loading, setLoading] = useState(true);

  const app = useContext(AppContext);
  const api = useContext(ApiContext);

  const { handleBlur, setFieldValue, handleSubmit, values, errors, touched, setValues } = useFormik<AppConfig>({
    initialValues: {
      loginPageMessage: "",
      welcomeMessage: "",
      subscriptionRequiredMessage: "",
      updatedAt: "",
    },
    validationSchema: AppConfigSchema,
    onSubmit: async (values) => {
      try {
        setReadOnly(true);
        await api.settings.postSettings(values);
        app.setAlert("Settings Saved", "success");
      } catch (e) {
        logger.error(e.message);
        app.setAlert(e.message, "error");
      } finally {
        setReadOnly(false);
      }
    },
  });

  useEffect(() => {
    const load = async () => {
      setLoading(true);

      try {
        const data = await api.settings.getSettings();
        setValues(data);
      } catch (error) {
        app.setAlert(error.message, "error")
      } finally {
        setLoading(false);
      }
    }

    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formProps = {
    /**
     * Returns error text if touched. Works with nested objects.
     */
    getError: (name: string): string => {
      return getIn(touched, name) && getIn(errors, name)
        ? getIn(errors, name)
        : "";
    },
    getValue: (name: string) => getIn(values, name),
    handleBlur,
    setFieldValue,
    readOnly
  }

  return (
    <Page title="Settings" loading={loading}>
      <Grid item xs={12}>
        <AppTextField
          name="loginPageMessage"
          label="Login Page Message"
          helperText="Will be displayed on the login page"
          multiline={true}
          {...formProps}
        />
        <AppTextField
          name="subscriptionRequiredMessage"
          label="Subscription Required"
          helperText="Will be displayed on the home page if user subscription is inactive"
          multiline={true}
          {...formProps}
        />
        <AppTextField
          name="welcomeMessage"
          label="Welcome Message"
          helperText="This will be the first message the user receives on sign-up"
          multiline={true}
          {...formProps}
        />
        <Button
          variant="contained"
          color="primary"
          className="submit-button"
          onClick={() => handleSubmit()}
          disabled={readOnly}
        >Save</Button>
      </Grid>
    </Page>
  );
}