import { ApiClientBase } from "./ApiClientBase";
import { getLogger } from "../LoggingService";
import { GalleriesApi } from "../../shared/api-interfaces";
import { Gallery, GalleryImages } from "../../shared/api-models";

export class GalleriesApiClient extends ApiClientBase implements GalleriesApi {

  constructor(apiUrl: string) {
    super(apiUrl, getLogger("GalleriesApiClient"), true)
  }

  async getGalleries(): Promise<Gallery[]> {
    const result = await this.get("/api/galleries")
    const data: Gallery[] = await result.json();
    return data;
  }

  async getImages(id: string): Promise<GalleryImages> {
    const result = await this.get(`/api/galleries/${id}`)
    const data: GalleryImages = await result.json();
    return data;
  }

}